const calculateCognitiveFatigue = (rrIntervals) => {
    if (rrIntervals.length < 15) return null;

    const { sd1, sd2 } = calculateSD1SD2(rrIntervals);
  
    if (sd1 === 0 || sd1 === null || sd2 === null) return null; // Avoid division by zero
  
    const stressIndex = Math.pow(sd2 / sd1, 2);
  
    return stressIndex;
  };

  const calculateRMSSD = (rrIntervals) => {
    if (rrIntervals.length < 15) return null;
    let sumSquares = 0;
    for (let i = 1; i < rrIntervals.length; i++) {
      let diff = rrIntervals[i] - rrIntervals[i - 1];
      sumSquares += diff * diff;
    }
    const rmssd = Math.sqrt(sumSquares / (rrIntervals.length - 1));
    return rmssd * 1000;
  };

  const calculateSDNN = (rrIntervals) => {
    if (rrIntervals.length < 15) return null;
    const mean = rrIntervals.reduce((a, b) => a + b) / rrIntervals.length;
    const sumSquares = rrIntervals.reduce((sum, val) => sum + Math.pow(val - mean, 2), 0);
    const sdnn = Math.sqrt(sumSquares / rrIntervals.length);
    return sdnn * 1000;
  };

  const calculateStressIndex = (rrIntervals) => {
    if (rrIntervals.length < 15) return null;
    const intervalsMs = rrIntervals.map((interval) => interval * 1000);
    const hist = {};

    intervalsMs.forEach((interval) => {
      const rounded = Math.round(interval / 40) * 40;
      hist[rounded] = (hist[rounded] || 0) + 1;
    });

    const mode = parseFloat(
      Object.keys(hist).reduce((a, b) => (hist[a] > hist[b] ? a : b))
    );
    const amo = (hist[mode] / intervalsMs.length) * 100;
    const varRR = Math.max(...intervalsMs) - Math.min(...intervalsMs);

    const stressIndex = (amo * 100) / (2 * mode * (varRR / 1000));

    return stressIndex;
  };

  const calculateHeartRate = (rrIntervals) => {
    if (rrIntervals.length < 15) return null;
    const avgRR = rrIntervals.reduce((a, b) => a + b) / rrIntervals.length;
    return 60 / avgRR;
  };

  const calculateReadiness = (stressIndex, heartRate) => {
    if (stressIndex == null || heartRate == null) return null;
    const readiness = 100 - stressIndex - heartRate / 2;
    return readiness;
  };

  const calculateSD1SD2 = (rrIntervals) => {
    if (rrIntervals.length < 15) return { sd1: null, sd2: null };
  
    const diffRR = [];
    for (let i = 1; i < rrIntervals.length; i++) {
      diffRR.push(rrIntervals[i] - rrIntervals[i - 1]);
    }
  
    const meanDiffRR = diffRR.reduce((a, b) => a + b) / diffRR.length;
  
    const sdDiffRR = Math.sqrt(
      diffRR.reduce((sum, val) => sum + Math.pow(val - meanDiffRR, 2), 0) / (diffRR.length - 1)
    );
  
    const sd1 = (sdDiffRR / Math.sqrt(2)) * 1000; // Convert to milliseconds
    const meanRR = rrIntervals.reduce((a, b) => a + b) / rrIntervals.length;
    const sdRR = Math.sqrt(
      rrIntervals.reduce((sum, val) => sum + Math.pow(val - meanRR, 2), 0) / (rrIntervals.length - 1)
    );
  
    const sd2 = Math.sqrt(2 * Math.pow(sdRR, 2) - 0.5 * Math.pow(sdDiffRR, 2)) * 1000; // Convert to milliseconds
  
    return { sd1, sd2 };
  };

export { calculateCognitiveFatigue, calculateRMSSD, calculateSDNN, calculateStressIndex, calculateHeartRate, calculateReadiness, calculateSD1SD2 };