import React, { useState } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import { logEvent } from "firebase/analytics";
import { analytics } from '../index.js';
import HRVSingleRecordingForm from './HRVSingleRecordingForm';
import FeedbackDialog from '../Live/FeedbackDialog';
import { Box, Button, Typography, Container, Card, CardContent, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const DemoPage: React.FC = () => {
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false);

  logEvent(analytics, 'Demo page visited');

  const handleFeedbackOpen = () => setFeedbackOpen(true);
  const handleFeedbackClose = () => setFeedbackOpen(false);

  return (
    <div className="container">
    <Container 
      maxWidth="lg" 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center'
      }}
    >
      <Accordion sx={{ my: 2, maxWidth: '600px'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="how-it-works-content"
          id="how-it-works-header"
        >
          <Typography variant="h4">
            <Box
              sx={{
                color: '#012B64',
                fontWeight: 700,
                my: 1,
                fontSize: '14px',
              }}
            >
              How does it work?
            </Box>
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ fontSize: '14px' }}>
          <ol>
            <li>Record data from your wearable device</li>
            <li>Extract data as a supported file format</li>
            <li>Upload the file using the form below</li>
            <li>Choose proper parameters</li>
            <li>Click "Calculate" to see your personalized HRV report</li>
          </ol>
          <Typography paragraph sx={{ fontSize: '14px' }}>
            Supported file formats: .csv, .edf (European Data Format), .acq (AcqKnowledge), .txt (text file), .json, .xml, .mat (Matlab)
          </Typography>
          <Typography paragraph sx={{ fontSize: '14px' }}>Please reach us if you're interested in more options.</Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ my: 2, maxWidth: '600px'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="integrate-device-content"
          id="integrate-device-header"
        >
          <Typography variant="h4">
            <Box
              component="span"
              sx={{
                color: '#012B64',
                fontWeight: 700,
                fontSize: '14px',
              }}
            >
              Integrate with device
            </Box>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography paragraph sx={{ fontSize: '14px' }}>
            Unlock the power of AI-driven Heart Rate Variability analysis by seamlessly integrating your wearable device with Open HRV.
          </Typography>
          <Button 
            component={Link} 
            to="/wearables-we-support" 
            variant="contained" 
            color="primary"
            sx={{ mt: 2, fontSize: '14px' }}
          >
            Learn More
          </Button>
        </AccordionDetails>
      </Accordion>

      <Box sx={{ my: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <HRVSingleRecordingForm />

      <Button variant="contained" color="secondary" onClick={handleFeedbackOpen} sx={{ mt: 4 }}>
        Leave Feedback for this demo
      </Button>



      <FeedbackDialog open={feedbackOpen} onClose={handleFeedbackClose} />
    </Box>
  </Container>
  </div>
);
}

export default DemoPage;
