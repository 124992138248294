import React from 'react';
import { 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  SelectChangeEvent 
} from '@mui/material';

export interface DevicePreset {
  name: string;
  samplingRate: number;
  dataType: 'PPG' | 'ECG' | 'RRS';
}

export const devicePresets: DevicePreset[] = [
  {
    name: 'Polar H10',
    samplingRate: 130,
    dataType: 'ECG'
  },
  {
    name: 'Empatica E4 (sample data)',
    samplingRate: 64,
    dataType: 'PPG'
  },
  {
    name: 'Custom',
    samplingRate: 100,
    dataType: 'PPG'
  }
];

interface DevicePresetsProps {
  onPresetSelect: (preset: DevicePreset) => void;
  currentDataType: string;
  currentSamplingRate: number;
}

const DevicePresets: React.FC<DevicePresetsProps> = ({
  onPresetSelect,
  currentDataType,
  currentSamplingRate
}) => {
  const getCurrentPreset = (): string => {
    const matchingPreset = devicePresets.find(
      preset => preset.samplingRate === currentSamplingRate && preset.dataType === currentDataType
    );
    return matchingPreset?.name || 'Custom';
  };

  const handleChange = (event: SelectChangeEvent) => {
    const selectedPreset = devicePresets.find(preset => preset.name === event.target.value);
    if (selectedPreset) {
      onPresetSelect(selectedPreset);
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel>Device Preset</InputLabel>
      <Select
        value={getCurrentPreset()}
        label="Device Preset"
        onChange={handleChange}
      >
        {devicePresets.map((preset) => (
          <MenuItem key={preset.name} value={preset.name}>
            {preset.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DevicePresets; 